.table-box {
	height: 200px;
	overflow-y: auto;

	.form-table {
		width: 100%;
		text-align: center;
		border: 1px solid #0000000f;

		tr,
		td,
		th {
			border: 1px solid #0000000f;
			.ant-form-item {
				margin-bottom: 0;
			}
		}

		.text-box {
			width: 120px;
			margin: 0 auto;
			text-align: left;
		}
	}
}

@primary-color: #287ED8;@primary-1: #E7F7FF;@a-color: #fff;