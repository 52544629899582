.spylist-tree.ant-tree {
	.ant-tree-treenode {
		height: 40px;
		align-items: center;
	}

	.ant-tree-switcher {
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
