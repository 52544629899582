.color-red {
	color: #f60001;
}

.color-blue {
	color: #0bbbff;
}

.color-green {
	color: #3ab54b;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.status_grey {
	background-color: #a0a0a0;
}

.status_green {
	background-color: #21bf37;
}

.status_orange {
	background-color: #ff8f00;
}

.status_red {
	background-color: #ff6e64;
}

.event-icon {
	width: 30px;
	height: 30px;
	display: inline-block;
	background: url('/assets/terminal-history.png');
}

.status-dot {
	height: 12px;
	width: 12px;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
	margin-top: 5px;
}

.custom-scroll-bar {
	&::-webkit-scrollbar {
		display: none;
		width: 4px;
		height: 4px;
		background-color: #fff;
	}
	&::-webkit-scrollbar-track {
		background-color: transparent;
		-webkit-border-radius: 2em;
		-moz-border-radius: 2em;
		border-radius: 2em;
	}
	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
		-webkit-border-radius: 2em;
		-moz-border-radius: 2em;
		border-radius: 2em;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba(0, 0, 0, 0.5);
		-webkit-border-radius: 2em;
		-moz-border-radius: 2em;
		border-radius: 2em;
	}
	&:hover {
		&::-webkit-scrollbar {
			display: block;
		}
	}
}
